import CoreText from 'components/Core/CoreText'
import SignupForm from 'pages/_serverRendered/EventSignup/SignupForm'
import Container from 'pages/_serverRendered/AeEventSignup/components/Container'
import {
  eventsAddedUserState,
  eventsCreatingPropertyTokenState,
  eventsPropsState,
  eventsSelectedAeIdState,
  eventsSelectedMarketState,
  eventsSelectedModeState,
  useResetEventOnboarding,
} from 'recoil/events'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'

const UserInfo: FC = () => {
  const mode = useRecoilValue(eventsSelectedModeState)
  const setUser = useSetRecoilState(eventsAddedUserState)
  const selectedAe = useRecoilValue(eventsSelectedAeIdState)
  const selectedMarket = useRecoilValue(eventsSelectedMarketState)
  const props = useRecoilValue(eventsPropsState)
  const [alert, setAlert] = useState<{ type: 'error' | 'success'; message: string } | null>(null)

  const setCreatingPropertyToken = useSetRecoilState(eventsCreatingPropertyTokenState)
  const reset = useResetEventOnboarding()

  const signupType = useMemo(() => {
    if (!props?.sales_events) return 'non-homeshow-event'

    const selectedEvent = props.sales_events.find((x) => x.name == selectedMarket)
    if (!selectedEvent) return 'non-homeshow-event'

    return selectedEvent.event_type == 'home_show' ? 'homeshow' : 'non-homeshow-event'
  }, [props?.sales_events, selectedMarket])

  const history = useHistory()

  const handleSuccess = useCallback(
    async (user, creatingPropertyToken) => {
      setUser(user)
      setCreatingPropertyToken(creatingPropertyToken)

      switch (mode) {
        case 'meeting':
          history.push('schedule-meeting')
          break
        case 'deposit':
          history.push('pay-deposit')
          break
        default:
          reset()
          history.push('/', { success: true })
          break
      }
    },
    [mode, history, setUser, reset, setCreatingPropertyToken]
  )

  return (
    <Container>
      <SignupForm
        alert={alert}
        setAlert={setAlert}
        onSuccess={handleSuccess}
        header={
          <CoreText.Headline
            className="tw-text-center tw-text-4xl sm:tw-text-left tw-mb-8 tw-mt-4 sm:tw-mt-0"
            size="xs"
          >
            {'To get started, tell us a bit about you and your home.'}
          </CoreText.Headline>
        }
        className="tw-space-y-4"
      >
        <input type="hidden" name="user[sfdc_lead_attrs][OwnerId]" value={selectedAe} />
        <input type="hidden" name="user[sfdc_lead_attrs][Name_of_market__c]" value={selectedMarket} />
        <input type="hidden" name="bypass_signin" value="true" />
        <input type="hidden" name="user[non_app_signup_type]" value={signupType} />
      </SignupForm>
    </Container>
  )
}

export default UserInfo
