import { BlogHomeData } from 'pages/_serverRendered/CmsPage/types'
import StyledRichText from 'components/MarketingAndPropertyPages/StyledRichText'
import Footer from 'components/MarketingSite/Footer'
import Header from 'components/MarketingSite/Header'
import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'
import { RichTextBlock } from 'prismic-reactjs'
import React, { FC } from 'react'
import Section from 'pages/_serverRendered/Public/shared/Section'
import Statistics from 'pages/_serverRendered/Public/shared/Statistics'
import FindPropertyCTA from 'pages/_serverRendered/PublicNeighborhood/FindPropertyCTA'
import GetRealmScoreCTA from 'pages/_serverRendered/PublicNeighborhood/GetRealmScoreCTA'
import Projects from 'pages/_serverRendered/Public/shared/Projects'
import IntroCopy from 'pages/_serverRendered/Public/shared/IntroCopy'
import styles from './styles.module.scss'
import PopularCities from 'pages/_serverRendered/Public/shared/PopularCities'
import { AggregateStats, PopularCity, TopProject } from 'recoil/publicNeighborhood'
import HomeSearchListings, { HomeSearchResultItem } from 'pages/_serverRendered/HomeSearch/HomeSearchListings'
import InternalLinks, { InternalLink } from 'components/InternalLinks'
import PageLoad from 'non-rendering/PageLoad'

interface PublicStateProps {
  blog_home_data: BlogHomeData
  state_code: string
  state_name: string
  top_projects: TopProject[]
  cities: PopularCity[]
  state_description: RichTextBlock[]
  stats: AggregateStats
  listings: HomeSearchResultItem[]
  internal_links?: InternalLink[]
}

const State: FC<PublicStateProps> = ({
  blog_home_data,
  state_code,
  state_name,
  stats,
  top_projects,
  state_description,
  cities,
  listings,
  internal_links,
}) => {
  return (
    <div>
      <PageLoad name={`public-state-${state_code}`} />
      <Header>
        <AddressAutocomplete
          placeholder={['Enter an address to view a property', 'Enter an address']}
          positionLabel="header"
        />
      </Header>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <section>
            <IntroCopy />
          </section>
          <hr />
          <section>
            <h1>
              {state_name}
              {' Real Estate & Home Prices'}
            </h1>
            <HomeSearchListings results={listings} columns={4} loading={false} />
          </section>
          <section>
            <div className={styles.breadcrumb}>
              <a href="/">{'Realm'}</a>
              <span className={styles.separator}>{'→'}</span>
              <strong>{state_name}</strong>
            </div>
          </section>
          <Section>
            <Statistics name={state_name} stats={stats} />
          </Section>
          <section className={styles.popularProjects}>
            <h2>{`Popular Projects in ${state_name}`}</h2>
            <p className={styles.sectionDescription}>
              {
                'Our pricing estimates use local labor & material costs. With your free Realm account, you can customize pricing based on square footage and quality of materials.'
              }
            </p>
            <Projects projects={top_projects} />
          </section>
          <FindPropertyCTA className={styles.findPropertyCta} />
          {state_description != null && (
            <Section>
              <h2>{state_name}</h2>
              <StyledRichText richText={state_description} />
            </Section>
          )}
          <Section className={styles.transparent}>
            <PopularCities cities={cities} stateCode={state_code} title={`Popular Cities in ${state_name}`} />
          </Section>
          {internal_links && internal_links.length > 0 && (
            <Section className={styles.transparent}>
              <h2>{'Explore more on Realm'}</h2>
              <InternalLinks links={internal_links} />
            </Section>
          )}
        </div>
        <GetRealmScoreCTA />
        <Footer blog_home_data={blog_home_data} />
      </div>
    </div>
  )
}

export default State
