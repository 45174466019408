// The content of this page is copy/pasted - disabling some linter rules to avoid having
// to hand-edit everything
/* eslint-disable react/jsx-no-literals, react/jsx-no-bind, jsx-a11y/anchor-is-valid */
import classNames from 'classnames'
import React, { FC, useEffect } from 'react'

import Footer from 'components/MarketingSite/Footer'
import Header from 'components/MarketingSite/Header'
import { trackPage } from 'utils/analytics'
import { BlogHomeData } from 'pages/_serverRendered/CmsPage/types'

import legalStyles from 'components/MarketingSite/shared/legal.module.scss'
import styles from './styles.module.scss'

const Content: FC = () => (
  <div className={legalStyles.wrapper}>
    <div className={classNames(styles.terms, legalStyles.legal)}>
      <h1 className="c21" id="h.gjdgxs">
        <span className="c4">Realm Terms of Use</span>
      </h1>
      <p className="c25">
        <span className="c1">Last Updated: 2/9/2021</span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          Welcome to Realm, a property potential platform. &nbsp;Other platforms may tell you what your home is worth
          today. &nbsp;Realm tells you what your home could be worth. &nbsp;Our insights help homeowners: increase the
          sale price of their home, pull money out of their home, generate rental income, and enjoy their space more.
          &nbsp;Please read on to learn the rules and restrictions that govern your use of our website(s), products,
          services and applications (the &ldquo;Services&rdquo; or the &ldquo;Realm Platform&rdquo;). If you have any
          questions, comments, or concerns regarding these terms or the Services, please contact us at:
        </span>
      </p>
      <p className="c35">
        <span className="c1">Email: </span>
        <span className="c1 c22 c38">
          <a className="c23" href="mailto:info@realmhome.com">
            info@realmhome.com
          </a>
        </span>
        <span className="c2 c1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      </p>
      <p className="c5">
        <span className="c2 c1">Address: 333 Hudson Street, suite 201, New York, New York 10013 </span>
      </p>
      <p className="c5">
        <span className="c1">These Terms of Use (the &ldquo;Terms&rdquo;) are a binding contract between you and </span>
        <span className="c8">REALM LIVING, INC.</span>
        <span className="c1">
          &nbsp;(&ldquo;Realm,&rdquo; &ldquo;we&rdquo; and &ldquo;us&rdquo;). Your use of the Services in any way means
          that you agree to all of these Terms, and these Terms will remain in effect while you use the Services. These
          Terms include the provisions in this document as well as those in the
        </span>
        <span className="c1">&nbsp;</span>
        <a href="https://realmhome.com/privacy" id="cmnt_ref1">
          <span className="c1 c10">Privacy Policy</span>
        </a>
        <span className="c1">.</span>
        <span className="c8">&nbsp;</span>
      </p>
      <p className="c5">
        <span className="c8">Please read these Terms carefully. </span>
        <span className="c1">
          They cover important information about Services provided to you and any charges, taxes, and fees we bill you.{' '}
        </span>
        <span className="c2 c8">
          These Terms include information about future changes to these Terms, automatic renewals, limitations of
          liability, a class action waiver and resolution of disputes by arbitration instead of in court. PLEASE NOTE
          THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF
          THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
        </span>
      </p>
      <p className="c5">
        <span className="c8">ARBITRATION NOTICE AND CLASS ACTION WAIVER:</span>
        <span className="c1">
          &nbsp;EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION AGREEMENT SECTION BELOW, YOU AGREE
          THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT
          TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
        </span>
      </p>
      <h2 className="c0">
        <span className="c4">Will these Terms ever change?</span>
      </h2>
      <p className="c5">
        <span className="c2 c1">
          We are constantly trying to improve our Services, so these Terms may need to change along with our Services.
          We reserve the right to change the Terms at any time, but if we do, we will place a notice on our site located
          at https://realmhome.com/, send you an email, and/or notify you by some other means.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          If you don&rsquo;t agree with the new Terms, you are free to reject them; unfortunately, that means you will
          no longer be able to use the Services. If you use the Services in any way after a change to the Terms is
          effective, that means you agree to all of the changes.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          Except for changes by us as described here, no other amendment or modification of these Terms will be
          effective unless in writing and signed by both you and us.
        </span>
      </p>
      <h2 className="c0">
        <span className="c4">What about my privacy?</span>
      </h2>
      <p className="c5">
        <span className="c1">
          Realm takes the privacy of its users very seriously. For the current Realm Privacy Policy, please{' '}
          <a href="https://realmhome.com/privacy" id="cmnt_ref2">
            click here
          </a>
        </span>
      </p>
      <p className="c5">
        <span className="c6 c1">Children&rsquo;s Online Privacy Protection Act</span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          The Children&rsquo;s Online Privacy Protection Act (&ldquo;COPPA&rdquo;) requires that online service
          providers obtain parental consent before they knowingly collect personally identifiable information online
          from children who are under 13 years of age. We do not knowingly collect or solicit personally identifiable
          information from children under 13 years of age; if you are a child under 13 years of age, please do not
          attempt to register for or otherwise use the Services or send us any personal information. If we learn we have
          collected personal information from a child under 13 years of age, we will delete that information as quickly
          as possible. If you believe that a child under 13 years of age may have provided us personal information,
          please contact us at info@realmhome.com.
        </span>
      </p>
      <h2 className="c0">
        <span className="c4">What are the basics of using Realm?</span>
      </h2>
      <p className="c5">
        <span className="c2 c1">
          The Realm Platform is a web-based communications platform that provides a variety of services and insights
          designed to increase the sale price of your home, pull money out of your home, generate rental income, and
          enjoy your space more. &nbsp;The Realm Platform also enables connections between (a) homeowners, consumers,
          property managers, individuals and other persons (&ldquo;Clients&rdquo;) seeking obtain certain project based
          services including, for example, home improvement and other related services (&ldquo;Projects&rdquo;) and (b)
          businesses seeking to perform Projects for Clients (&ldquo;Vendors&rdquo;). &nbsp;Clients and Vendors together
          are hereinafter referred to as &ldquo;Users.&rdquo; VENDORS ARE INDEPENDENT BUSINESS OWNERS AND INDEPENDENT
          CONTRACTORS OF CLIENTS AND NOT EMPLOYEES, PARTNERS, REPRESENTATIVES, AGENTS, JOINT VENTURERS, INDEPENDENT
          CONTRACTORS OR FRANCHISEES OF REALM. REALM DOES NOT PERFORM TASKS AND DOES NOT EMPLOY INDIVIDUALS TO PERFORM
          PROJECTS. BY CONNECTING PEOPLE AND BUSINESSES SEEKING SERVICES WITH VENDORS, REALM OPERATES AS AN ONLINE
          MARKETPLACE THAT CONNECTS CLIENTS WITH VENDORS WHO WISH TO PERFORM A VARIETY OF PROJECTS.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          USERS HEREBY ACKNOWLEDGE THAT REALM DOES NOT SUPERVISE, SCOPE, DIRECT, CONTROL OR MONITOR A VENDOR&rsquo;S
          WORK AND EXPRESSLY DISCLAIMS (TO THE EXTENT PERMITTED BY LAW) ANY RESPONSIBILITY AND LIABILITY FOR THE WORK
          PERFORMED AND THE PROJECT IN ANY MANNER, INCLUDING BUT NOT LIMITED TO A WARRANTY OR CONDITION OF GOOD AND
          WORKMANLIKE SERVICES, WARRANTY OR CONDITION OF QUALITY OR FITNESS FOR A PARTICULAR PURPOSE, OR COMPLIANCE WITH
          ANY LAW, STATUTE, ORDINANCE, REGULATION, OR CODE.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          Any reference on the Realm Platform to a Vendor being licensed or credentialed in some manner indicates only
          that the Vendor has completed a relevant account process or met certain criteria and does not represent
          anything else. Any such description is not an endorsement, certification or guarantee by Vendor of such
          Vendor&rsquo;s skills or qualifications or whether they are licensed, insured, trustworthy, safe or suitable.
          Instead, any such description is intended to be useful information for Clients to evaluate when they make
          their own decisions about the identity and suitability of Vendor whom they contact or interact with via the
          Realm Platform.
        </span>
      </p>
      <p className="c33">
        <span className="c2 c1">
          The Realm Platform enables connections between Users for the fulfillment of Project. Realm is not responsible
          for the performance or communications of Users, nor does it have control over the quality, timing, legality,
          failure to provide, or any other aspect whatsoever of Projects, Vendors, or Clients, nor of the integrity,
          responsibility, competence, qualifications, or any of the actions or omissions whatsoever of any Users, or of
          any ratings or reviews provided by Users with respect to each other. Realm makes no warranties or
          representations about the suitability, reliability, timeliness, or accuracy of the Project requested or
          services provided by, or the communications of or between, Users identified through the Realm Platform,
          whether in public or private, via on- or off-line interactions, or otherwise howsoever.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          You may be required to sign up for an account, select a password and user name (&ldquo;Realm User ID&rdquo;),
          and provide us with certain information or data, such as your contact information. You promise to provide us
          with accurate, complete, and updated registration information about yourself. You may not select as your Realm
          User ID a name that you do not have the right to use, or another person&rsquo;s name with the intent to
          impersonate that person. You may not transfer your account to anyone else without our prior written
          permission.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          Additionally, you may be able to access certain parts or features of the Services by using your account
          credentials from other services (each, a &ldquo;Third Party Account&rdquo;), such as those offered by Google
          and Facebook. By using the Services through a Third Party Account, you permit us to access certain information
          from such account for use by the Services. You are ultimately in control of how much information is accessible
          to us and may exercise such control by adjusting your privacy settings on your Third Party Account.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          You represent and warrant that you are an individual of legal age to form a binding contract (or if not,
          you&rsquo;ve received your parent&rsquo;s or guardian&rsquo;s permission to use the Services and have gotten
          your parent or guardian to agree to these Terms on your behalf). If you&rsquo;re agreeing to these Terms on
          behalf of an organization or entity, you represent and warrant that you are authorized to agree to these Terms
          on that organization&rsquo;s or entity&rsquo;s behalf and bind them to these Terms (in which case, the
          references to &ldquo;you&rdquo; and &ldquo;your&rdquo; in these Terms, except for in this sentence, refer to
          that organization or entity).
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          You will only use the Services for your own internal, personal, non-commercial use, and not on behalf of or
          for the benefit of any third party, and only in a manner that complies with all laws that apply to you. If
          your use of the Services is prohibited by applicable laws, then you aren&rsquo;t authorized to use the
          Services. We can&rsquo;t and won&rsquo;t be responsible for your using the Services in a way that breaks the
          law.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          You will not share your Realm User ID, account or password with anyone, and you must protect the security of
          your Realm User ID, account, password and any other access tools or credentials. You&rsquo;re responsible for
          any activity associated with your Realm User ID and account.
        </span>
      </p>
      <h2 className="c0">
        <span className="c4">What about messaging?</span>
      </h2>
      <p className="c5">
        <span className="c1 c28">
          As part of the Services, you may receive communications through the Services, including messages that Realm
          sends you (for example, via email or SMS).{' '}
        </span>
        <span className="c8 c28">
          By signing up for the Services and providing us with your wireless number, you confirm that you want Realm to
          send you information regarding your account or transactions with us, which may include Realm using automated
          dialing technology to text you at the wireless number you provided, and you agree to receive communications
          from Realm, and you represent and warrant that each person you register for the Services or for whom you
          provide a wireless phone number has consented to receive communications from Realm.
        </span>
        <span className="c2 c1">
          &nbsp;You agree to indemnify and hold Realm harmless from and against any and all claims, liabilities, damages
          (actual and consequential), losses and expenses (including attorneys&rsquo; fees) arising from or in any way
          related to your breach of the foregoing.
        </span>
      </p>
      <h2 className="c0">
        <span className="c4">Are there restrictions in how I can use the Services?</span>
      </h2>
      <p className="c5">
        <span className="c2 c1">
          You represent, warrant, and agree that you will not contribute any Content or User Submission (each of those
          terms is defined below) or otherwise use the Services or interact with the Services in a manner that:
        </span>
      </p>
      <ol className="c14 lst-kix_list_2-0 start" start={1}>
        <li className="c24 li-bullet-0">
          <span className="c2 c1">
            infringes or violates the intellectual property rights or any other rights of anyone else (including Realm);
          </span>
        </li>
        <li className="c24 li-bullet-0">
          <span className="c2 c1">
            violates any law or regulation, including, without limitation, any applicable export control laws, privacy
            laws or any other purpose not reasonably intended by Realm;
          </span>
        </li>
        <li className="c24 li-bullet-0">
          <span className="c2 c1">
            is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise
            objectionable;
          </span>
        </li>
        <li className="c24 li-bullet-0">
          <span className="c2 c1">
            jeopardizes the security of your Realm User ID, account or anyone else&rsquo;s (such as allowing someone
            else to log in to the Services as you);
          </span>
        </li>
        <li className="c24 li-bullet-0">
          <span className="c2 c1">
            attempts, in any manner, to obtain the password, account, or other security information from any other user;
          </span>
        </li>
        <li className="c24 li-bullet-0">
          <span className="c2 c1">
            violates the security of any computer network, or cracks any passwords or security encryption codes;
          </span>
        </li>
        <li className="c24 li-bullet-0">
          <span className="c2 c1">
            runs Maillist, Listserv, any form of auto-responder or &ldquo;spam&rdquo; on the Services, or any processes
            that run or are activated while you are not logged into the Services, or that otherwise interfere with the
            proper working of the Services (including by placing an unreasonable load on the Services&rsquo;
            infrastructure);
          </span>
        </li>
        <li className="c24 li-bullet-0">
          <span className="c2 c1">
            &ldquo;crawls,&rdquo; &ldquo;scrapes,&rdquo; or &ldquo;spiders&rdquo; any page, data, or portion of or
            relating to the Services or Content (through use of manual or automated means);
          </span>
        </li>
        <li className="c24 li-bullet-0">
          <span className="c2 c1">copies or stores any significant portion of the Content; or</span>
        </li>
        <li className="c24 li-bullet-0">
          <span className="c2 c1">
            decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or
            information of or relating to the Services.
          </span>
        </li>
      </ol>
      <p className="c31 c34">
        <span className="c2 c1"></span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          A violation of any of the foregoing is grounds for termination of your right to use or access the Services.
        </span>
      </p>
      <h2 className="c0">
        <span className="c4">What are my rights in the Services?</span>
      </h2>
      <p className="c5">
        <span className="c2 c1">
          The materials displayed or performed or available on or through the Services, including, but not limited to,
          text, graphics, data, articles, photos, images, illustrations and so forth (all of the foregoing, the
          &ldquo;Content&rdquo;) are protected by copyright and/or other intellectual property laws. You promise to
          abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you
          access through the Services, and you won&rsquo;t use, copy, reproduce, modify, translate, publish, broadcast,
          transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any
          purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a
          way that violates someone else&rsquo;s (including Realm&#39;s) rights.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          Subject to these Terms, we grant each user of the Services a non-exclusive, non-sublicensable and
          non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using
          the Services. Use, reproduction, modification, distribution or storage of any Content for any purpose other
          than using the Services is expressly prohibited without prior written permission from us. You understand that
          Realm owns the Services. You won&rsquo;t modify, publish, transmit, participate in the transfer or sale of,
          reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise
          exploit any of the Services. The Services may allow you to copy or download certain Content, but please
          remember that even where these functionalities exist, all the restrictions in this section still apply.
        </span>
      </p>
      <h2 className="c0">
        <span className="c4">What about anything I contribute to the Services?</span>
      </h2>
      <p className="c5">
        <span className="c6 c1 c9">User Submissions</span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          Anything you post, upload, share, store, or otherwise provide through the Services is your &ldquo;User
          Submission&rdquo;. Some User Submissions may be viewable by other users. You are solely responsible for all
          User Submissions you contribute to the Services. You represent that all User Submissions submitted by you are
          accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          You agree that you will not post, upload, share, store, or otherwise provide through the Services any User
          Submissions that: (i) infringe any third party&#39;s copyrights or other rights (e.g., trademark, privacy
          rights, etc.); (ii) contain sexually explicit content or pornography; (iii) contain hateful, defamatory, or
          discriminatory content or incite hatred against any individual or group; (iv) exploit minors; (v) depict
          unlawful acts or extreme violence; (vi) depict animal cruelty or extreme violence towards animals; (vii)
          promote fraudulent schemes, multi-level marketing (MLM) schemes, get rich quick schemes, online gaming and
          gambling, cash gifting, work from home businesses, or any other dubious money-making ventures; or (viii) that
          violate any law.
        </span>
      </p>
      <p className="c5">
        <span className="c6 c1 c9">Licenses</span>
      </p>
      <p className="c5">
        <span className="c1">
          In order to display your User Submissions on the Services, and to allow other users to enjoy them (where
          applicable), you grant us certain rights in those User Submissions (see below for more information). Please
          note that all of the following licenses are subject to our{' '}
        </span>
        <a href="https://realmhome.com/privacy" id="cmnt_ref3">
          <span className="c1 c10">Privacy Policy </span>
        </a>
        <span className="c2 c1">
          to the extent they relate to User Submissions that are also your personally-identifiable information.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          By submitting User Submissions through the Services, you hereby do and shall grant Realm a worldwide,
          non-exclusive, perpetual, royalty-free, fully paid, sublicensable and transferable license to use, edit,
          modify, truncate, aggregate, reproduce, distribute, prepare derivative works of, display, perform, and
          otherwise fully exploit the User Submissions in connection with this site, the Services and our (and our
          successors&rsquo; and assigns&rsquo;) businesses, including without limitation for promoting and
          redistributing part or all of this site or the Services (and derivative works thereof) in any media formats
          and through any media channels (including, without limitation, third party websites and feeds), and including
          after your termination of your account or the Services. You also hereby do and shall grant each user of this
          site and/or the Services a non-exclusive, perpetual license to access your User Submissions through this site
          and/or the Services, and to use, edit, modify, reproduce, distribute, prepare derivative works of, display and
          perform such User Submissions, including after your termination of your account or the Services. For clarity,
          the foregoing license grants to us and our users do not affect your other ownership or license rights in your
          User Submissions, including the right to grant additional licenses to your User Submissions, unless otherwise
          agreed in writing. You represent and warrant that you have all rights to grant such licenses to us without
          infringement or violation of any third party rights, including without limitation, any privacy rights,
          publicity rights, copyrights, trademarks, contract rights, or any other intellectual property or proprietary
          rights.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          Finally, you understand and agree that Realm, in performing the required technical steps to provide the
          Services to our users (including you), may need to make changes to your User Submissions to conform and adapt
          those User Submissions to the technical requirements of connection networks, devices, services, or media, and
          the foregoing licenses include the rights to do so.
        </span>
      </p>
      <h2 className="c0">
        <span className="c4">What if I see something on the Services that infringes my copyright?</span>
      </h2>
      <p className="c5">
        <span className="c2 c1">
          In accordance with the DMCA, we&rsquo;ve adopted the following policy toward copyright infringement. &nbsp;We
          reserve the right to (1) block access to or remove material that we believe in good faith to be copyrighted
          material that has been illegally copied and distributed by any of our advertisers, affiliates, content
          providers, members or users and (2) remove and discontinue service to repeat offenders.
        </span>
      </p>
      <ol className="c14 lst-kix_list_3-0 start" start={1}>
        <li className="c5 c32 li-bullet-0">
          <span className="c6 c1">Procedure for Reporting Copyright Infringements</span>
          <span className="c2 c1">
            . &nbsp;If you believe that material or content residing on or accessible through the Services infringes
            your copyright (or the copyright of someone whom you are authorized to act on behalf of), please send a
            notice of copyright infringement containing the following information to Realm&#39;s Designated Agent to
            Receive Notification of Claimed Infringement (our &ldquo;Designated Agent,&rdquo; whose contact details are
            listed below):
          </span>
        </li>
      </ol>
      <ol className="c14 lst-kix_list_4-0 start" start={1}>
        <li className="c12 li-bullet-0">
          <span className="c2 c1">
            A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright
            that has been allegedly infringed;
          </span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2 c1">Identification of works or materials being infringed;</span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2 c1">
            Identification of the material that is claimed to be infringing including information regarding the location
            of the infringing materials that the copyright owner seeks to have removed, with sufficient detail so that
            Company is capable of finding and verifying its existence;
          </span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2 c1">
            Contact information about the notifier including address, telephone number and, if available, email address;
          </span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2 c1">
            A statement that the notifier has a good faith belief that the material identified in (1)(c) is not
            authorized by the copyright owner, its agent, or the law; and
          </span>
        </li>
        <li className="c5 c39 li-bullet-0">
          <span className="c2 c1">
            A statement made under penalty of perjury that the information provided is accurate and the notifying party
            is authorized to make the complaint on behalf of the copyright owner.
          </span>
        </li>
      </ol>
      <ol className="c14 lst-kix_list_3-0" start={2}>
        <li className="c5 c32 li-bullet-0">
          <span className="c1 c6">
            Once Proper Bona Fide Infringement Notification is Received by the Designated Agent
          </span>
          <span className="c2 c1">
            . &nbsp;Upon receipt of a proper notice of copyright infringement, we reserve the right to:
          </span>
        </li>
      </ol>
      <ol className="c14 lst-kix_list_5-0 start" start={1}>
        <li className="c12 li-bullet-0">
          <span className="c2 c1">remove or disable access to the infringing material;</span>
        </li>
        <li className="c12 li-bullet-0">
          <span className="c2 c1">
            notify the content provider who is accused of infringement that we have removed or disabled access to the
            applicable material; and
          </span>
        </li>
        <li className="c5 c39 li-bullet-0">
          <span className="c2 c1">
            terminate such content provider&#39;s access to the Services if he or she is a repeat offender.
          </span>
        </li>
      </ol>
      <ol className="c14 lst-kix_list_3-0" start={3}>
        <li className="c5 c32 li-bullet-0">
          <span className="c6 c1">Procedure to Supply a Counter-Notice to the Designated Agent</span>
          <span className="c2 c1">
            . &nbsp;If the content provider believes that the material that was removed (or to which access was
            disabled) is not infringing, or the content provider believes that it has the right to post and use such
            material from the copyright owner, the copyright owner&#39;s agent, or, pursuant to the law, the content
            provider may send us a counter-notice containing the following information to the Designated Agent:
          </span>
        </li>
      </ol>
      <ol className="c14 lst-kix_list_6-0 start" start={1}>
        <li className="c26 li-bullet-1">
          <span className="c2 c1">A physical or electronic signature of the content provider;</span>
        </li>
        <li className="c26 li-bullet-1">
          <span className="c2 c1">
            Identification of the material that has been removed or to which access has been disabled and the location
            at which the material appeared before it was removed or disabled;
          </span>
        </li>
        <li className="c26 li-bullet-1">
          <span className="c2 c1">
            A statement that the content provider has a good faith belief that the material was removed or disabled as a
            result of mistake or misidentification of the material; and
          </span>
        </li>
        <li className="c5 c29 li-bullet-1">
          <span className="c2 c1">
            Content provider&#39;s name, address, telephone number, and, if available, email address, and a statement
            that such person or entity consents to the jurisdiction of the Federal Court for the judicial district in
            which the content provider&rsquo;s address is located, or, if the content provider&#39;s address is located
            outside the United States, for any judicial district in which Company is located, and that such person or
            entity will accept service of process from the person who provided notification of the alleged infringement.
          </span>
        </li>
      </ol>
      <p className="c5 c30">
        <span className="c2 c1">
          If a counter-notice is received by the Designated Agent, Company may, in its discretion, send a copy of the
          counter-notice to the original complaining party informing that person that Company may replace the removed
          material or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court
          order against the content provider accused of committing infringement, the removed material may be replaced or
          access to it restored in 10 to 14 business days or more after receipt of the counter-notice, at Company&#39;s
          discretion. &nbsp;
        </span>
      </p>
      <p className="c5 c30">
        <span className="c2 c1 c15">Please contact Realm&#39;s Designated Agent at the following address:</span>
      </p>
      <p className="c13">
        <span className="c2 c1 c15">Realm Living, Inc.</span>
      </p>
      <p className="c13">
        <span className="c2 c1 c15">Attn: DMCA Designated Agent </span>
      </p>
      <p className="c13">
        <span className="c1 c22 c15 c38">
          <a className="c23" href="mailto:info@realmhome.com">
            info@realmhome.com
          </a>
        </span>
        <span className="c2 c1 c15">&nbsp;</span>
      </p>
      <p className="c0 c30">
        <span className="c1 c15 c28">333 Hudson Street, </span>
        <span className="c15 c27">S</span>
        <span className="c2 c1 c15">uite 201, New York, New York 10013</span>
      </p>
      <h2 className="c0">
        <span className="c4">Who is responsible for what I see and do on the Services?</span>
      </h2>
      <p className="c5">
        <span className="c2 c1">
          Any information or Content publicly posted or privately transmitted through the Services is the sole
          responsibility of the person from whom such Content originated, and you access all such information and
          Content at your own risk, and we aren&rsquo;t liable for any errors or omissions in that information or
          Content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty
          to take any action regarding how you may interpret and use the Content or what actions you may take as a
          result of having been exposed to the Content, and you hereby release us from all liability for you having
          acquired or not acquired Content through the Services. We can&rsquo;t guarantee the identity of any users with
          whom you interact in using the Services and are not responsible for which users gain access to the Services.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          You are responsible for all Content you contribute, in any manner, to the Services, and you represent and
          warrant you have all rights necessary to do so, in the manner in which you contribute it.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1 c17">
          The Services may contain links or connections to third-party websites or services that are not owned or
          controlled by Realm. When you access third-party websites or use third-party services, you accept that there
          are risks in doing so, and that Realm is not responsible for such risks.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1 c17">
          Realm has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or
          practices of or opinions expressed in any third-party websites or by any third party that you interact with
          through the Services. In addition, Realm will not and cannot monitor, verify, censor or edit the content of
          any third-party site or service. We encourage you to be aware when you leave the Services and to read the
          terms and conditions and privacy policy of each third-party website or service that you visit or utilize. By
          using the Services, you release and hold us harmless from any and all liability arising from your use of any
          third-party website or service.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1 c17">
          Your interactions with organizations and/or individuals found on or through the Services, including payment
          and delivery of goods or services, and any other terms, conditions, warranties or representations associated
          with such dealings, are solely between you and such organizations and/or individuals. You should make whatever
          investigation you feel necessary or appropriate before proceeding with any online or offline transaction with
          any of these third parties. You agree that Realm shall not be responsible or liable for any loss or damage of
          any sort incurred as the result of any such dealings.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1 c17">
          If there is a dispute between participants on this site or Services, or between users and any third party, you
          agree that Realm is under no obligation to become involved. In the event that you have a dispute with one or
          more other users, you release Realm, its directors, officers, employees, agents, and successors from claims,
          demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or
          undisclosed, arising out of or in any way related to such disputes and/or our Services. You shall and hereby
          do waive California Civil Code Section 1542 or any similar law of any jurisdiction, which says in substance:
          &ldquo;A general release does not extend to claims that the creditor or releasing party does not know or
          suspect to exist in his or her favor at the time of executing the release and that, if known by him or her,
          would have materially affected his or her settlement with the debtor or released party.&rdquo;
        </span>
      </p>
      <h2 className="c0">
        <span className="c4">Will Realm ever change the Services?</span>
      </h2>
      <p className="c5">
        <span className="c2 c1">
          We&rsquo;re always trying to improve our Services, so they may change over time. We may suspend or discontinue
          any part of the Services, or we may introduce new features or impose limits on certain features or restrict
          access to parts or all of the Services. We&rsquo;ll try to give you notice when we make a material change to
          the Services that would adversely affect you, but this isn&rsquo;t always practical. We reserve the right to
          remove any Content from the Services at any time, for any reason (including, but not limited to, if someone
          alleges you contributed that Content in violation of these Terms), in our sole discretion, and without notice.
        </span>
      </p>
      <h2 className="c0">
        <span className="c4">Do the Services cost anything?</span>
      </h2>
      <p className="c5">
        <span className="c2 c1">
          The Services may be free or we may charge a fee for using the Services. If you are using a free version or
          free aspects of the Services, we will notify you before any Services you are then using begin carrying a fee,
          and if you wish to continue using such Services, you must pay all applicable fees for such Services. Note that
          if you elect to receive text messages through the Services, data and message rates may apply. Any and all such
          charges, fees or costs are your sole responsibility. You should consult with your wireless carrier to
          determine what rates, charges, fees or costs may apply to your use of the Services.
        </span>
      </p>
      <ol className="c14 lst-kix_list_1-0 start" start={1}>
        <li className="c3 li-bullet-2">
          <span className="c1 c22">Paid Services</span>
          <span className="c1">
            . Certain of our Services may be subject to payments now or in the future (the &ldquo;Paid Services&rdquo;).
            Please note that any payment terms presented to you in the process of using or signing up for a Paid Service
            are deemed part of these Terms and you agree to comply with all such terms in connection with your use of
            the Paid Service. The current Paid Services will be marked as such &amp; include a description of the
            Service.
          </span>
        </li>
        <li className="c3 li-bullet-2">
          <span className="c1 c22">Deposit</span>
          <span className="c1 c2">
            . In connection with any initial assessment or consultation, you may be required to submit a deposit (the
            &ldquo;Deposit&rdquo;). &nbsp;You understand and agree that the Deposit is intended to ensure your interest
            and engagement with our process. &nbsp;
          </span>
        </li>
        <li className="c3 li-bullet-2">
          <span className="c1 c22">Billing</span>
          <span className="c2 c1">
            . We use a third-party payment processor (the &ldquo;Payment Processor&rdquo;) to bill you through a payment
            account linked to your account on the Services (your &ldquo;Billing Account&rdquo;) for use of the Paid
            Services. The processing of payments will be subject to the terms, conditions and privacy policies of the
            Payment Processor in addition to these Terms. Currently, we use PayPal, Inc. as our Payment Processor. You
            can access PayPal&rsquo;s User Agreement at https://www.paypal.com/us/webapps/mpp/ua/useragreement-full and
            their Privacy Policy at https://www.paypal.com/us/webapps/mpp/ua/privacy-full. &nbsp;We are not responsible
            for any error by, or other acts or omissions of, the Payment Processor. By choosing to use Paid Services,
            you agree to pay us, through the Payment Processor, all charges at the prices then in effect for any use of
            such Paid Services in accordance with the applicable payment terms, and you authorize us, through the
            Payment Processor, to charge your chosen payment provider (your &ldquo;Payment Method&rdquo;). You agree to
            make payment using that selected Payment Method. We reserve the right to correct any errors or mistakes that
            the Payment Processor makes even if it has already requested or received payment.
          </span>
        </li>
        <li className="c3 li-bullet-2">
          <span className="c1 c22">Payment Method</span>
          <span className="c2 c1">
            . The terms of your payment will be based on your Payment Method and may be determined by agreements between
            you and the financial institution, credit card issuer or other provider of your chosen Payment Method. If
            we, through the Payment Processor, do not receive payment from you, you agree to pay all amounts due on your
            Billing Account upon demand.
          </span>
        </li>
        <li className="c3 li-bullet-2">
          <span className="c1 c22">Recurring Billing</span>
          <span className="c2 c1">
            . Some of the Paid Services may consist of an initial period, for which there is a one-time charge, followed
            by recurring period charges as agreed to by you. By choosing a recurring payment plan, you acknowledge that
            such Services have an initial and recurring payment feature and you accept responsibility for all recurring
            charges prior to cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) WITHOUT FURTHER AUTHORIZATION
            FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS
            AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE
            REASONABLY COULD ACT. TO TERMINATE YOUR AUTHORIZATION OR CHANGE YOUR PAYMENT METHOD, GO TO ACCOUNT SETTINGS.
          </span>
        </li>
        <li className="c3 li-bullet-2">
          <span className="c1 c22">Current Information Required</span>
          <span className="c2 c1">
            . YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY
            UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN
            BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR
            PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A
            POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD.
            CHANGES TO SUCH INFORMATION CAN BE MADE AT ACCOUNT SETTINGS. IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING
            INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES UNDER YOUR BILLING
            ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE.
          </span>
        </li>
        <li className="c3 li-bullet-2">
          <span className="c1 c22">Change in Amount Authorized</span>
          <span className="c2 c1">
            . If the amount to be charged to your Billing Account varies from the amount you preauthorized (other than
            due to the imposition or change in the amount of state sales taxes), you have the right to receive, and we
            shall provide, notice of the amount to be charged and the date of the charge before the scheduled date of
            the transaction. Any agreement you have with your payment provider will govern your use of your Payment
            Method. You agree that we may accumulate charges incurred and submit them as one or more aggregate charges
            during or at the end of each billing cycle.
          </span>
        </li>
        <li className="c3 li-bullet-2">
          <span className="c1 c22">Auto-Renewal for Paid Services</span>
          <span className="c1">
            . Unless you opt out of auto-renewal, which can be done through your account settings, any Paid Services you
            have signed up for will be automatically extended for successive renewal periods of the same duration as the
            subscription term originally selected, at the then-current non-promotional rate. To change or resign your
            Paid Services at any time, go to account settings. &nbsp;If you terminate a Paid Service, you may use your
            subscription until the end of your then-current term, and your subscription will not be renewed after your
            then-current term expires. However, you will not be eligible for a prorated refund of any portion of the
            subscription fee paid for the then-current subscription period.{' '}
          </span>
          <span className="c1">
            IF YOU DO NOT WANT TO CONTINUE TO BE CHARGED ON A RECURRING MONTHLY BASIS, YOU MUST CANCEL THE APPLICABLE
            PAID SERVICE THROUGH YOUR ACCOUNT SETTINGS OR TERMINATE YOUR{' '}
          </span>
          <span className="c1">REALM</span>
          <span className="c1">
            &nbsp;ACCOUNT BEFORE THE END OF THE RECURRING TERM. PAID SERVICES CANNOT BE TERMINATED BEFORE THE END OF THE
            PERIOD FOR WHICH YOU HAVE ALREADY PAID, AND EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS,{' '}
          </span>
          <span className="c1">REALM </span>
          <span className="c1">WILL NOT REFUND ANY FEES THAT YOU HAVE ALREADY PAID.</span>
        </li>
        <li className="c3 li-bullet-2">
          <span className="c1 c22">Reaffirmation of Authorization</span>
          <span className="c2 c1">
            . Your non-termination or continued use of a Paid Service reaffirms that we are authorized to charge your
            Payment Method for that Paid Service. We may submit those charges for payment and you will be responsible
            for such charges. This does not waive our right to seek payment directly from you. Your charges may be
            payable in advance, in arrears, per usage, or as otherwise described when you initially selected to use the
            Paid Service.
          </span>
        </li>
      </ol>
      <h2 className="c0">
        <span className="c4">What if I want to stop using the Services? </span>
      </h2>
      <p className="c5">
        <span className="c1">
          You&rsquo;re free to do that at any time by contacting us at info@realmhome.com; please refer to our
        </span>
        <span className="c1">&nbsp;</span>
        <a href="https://realmhome.com/privacy" id="cmnt_ref5">
          <span className="c1 c10">Privacy Policy</span>
        </a>
        <span className="c1">,</span>
        <span className="c2 c1">
          &nbsp;as well as the licenses above, to understand how we treat information you provide to us after you have
          stopped using our Services.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          Realm is also free to terminate (or suspend access to) your use of the Services or your account for any reason
          in our discretion, including your breach of these Terms. Realm has the sole right to decide whether you are in
          violation of any of the restrictions set forth in these Terms.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          Account termination may result in destruction of any Content associated with your account, so keep that in
          mind before you decide to terminate your account.
        </span>
      </p>
      <p className="c5">
        <span className="c1">If you have deleted your account by mistake, contact us immediately at </span>
        <span className="c1 c10">info@realmhome.com</span>
        <span className="c2 c1">
          &nbsp;&ndash; we will try to help, but unfortunately, we can&rsquo;t promise that we can recover or restore
          anything.
        </span>
      </p>
      <p className="c5">
        <span className="c2 c1">
          Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way
          of example, all of the following will survive termination: any obligation you have to pay us or indemnify us,
          any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms
          regarding disputes between us, including without limitation the arbitration agreement.
        </span>
      </p>
      <h2 className="c0">
        <span className="c4">What else do I need to know?</span>
      </h2>
      <p className="c5">
        <span className="c6 c1">Warranty Disclaimer</span>
        <span className="c1 c7">.</span>
        <span className="c1">
          &nbsp;Realm and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each of
          their respective officers, directors, members, employees, consultants, contract employees, representatives and
          agents, and each of their respective successors and assigns (Realm and all such parties together, the
          &ldquo;Realm Parties&rdquo;) make no representations or warranties concerning the Services, including without
          limitation regarding any Content contained in or accessed through the Services, and the Realm Parties will not
          be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in
          or accessed through the Services or any claims, actions, suits procedures, costs, expenses, damages or
          liabilities arising out of use of, or in any way related to your participation in, the Services. You
          acknowledge that all suggestions, recommendations and other information provided by Realm should not be
          interpreted as guarantee. &nbsp;We cannot guarantee any result or outcome including increases in property
          value or return on investment. &nbsp;
        </span>
        <span className="c1 c36">
          You hereby waive, promise never to assert, and release Realm and its subsidiaries, successors, assigns,
          partners, agents and employees from, all actions, claims or demands that you now have or may hereafter have
          for any injury, harm, damage, loss, cost or expense (including, without limitation, attorneys&rsquo; fees)
          suffered or incurred by you as a direct or indirect result of your use of any such suggestions,
          recommendations or other information. &nbsp;
        </span>
        <span className="c2 c1">
          The Realm Parties make no representations or warranties regarding suggestions or recommendations of services
          or products offered or purchased through or in connection with the Services. THE SERVICES AND CONTENT ARE
          PROVIDED BY REALM (AND ITS LICENSORS AND SUPPLIERS) ON AN &ldquo;AS-IS&rdquo; BASIS, WITHOUT WARRANTIES OF ANY
          KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE.
          SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT
          APPLY TO YOU.
        </span>
      </p>
      <p className="c5">
        <span className="c6 c1">Limitation of Liability</span>
        <span className="c2 c1">
          . TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY
          (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE REALM PARTIES
          BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL
          DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF
          GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY SUBSTITUTE GOODS,
          SERVICES OR TECHNOLOGY, (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) ONE-HUNDRED ($100)
          DOLLARS OR (II) THE AMOUNTS PAID AND/OR PAYABLE BY YOU TO REALM IN CONNECTION WITH THE SERVICES IN THE TWELVE
          (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM OR (D) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES
          DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL OR CERTAIN OTHER DAMAGES, SO THE ABOVE
          LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
        </span>
      </p>
      <p className="c5">
        <span className="c6 c1">Indemnity</span>
        <span className="c2 c1">
          . You agree to indemnify and hold the Realm Parties harmless from and against any and all claims, liabilities,
          damages (actual and consequential), losses and expenses (including attorneys&rsquo; fees) arising from or in
          any way related to any claims relating to (a) your use of the Services (including any actions taken by a third
          party using your account), and (b) your violation of these Terms. In the event of such a claim, suit, or
          action (&ldquo;Claim&rdquo;), we will attempt to provide notice of the Claim to the contact information we
          have for your account (provided that failure to deliver such notice shall not eliminate or reduce your
          indemnification obligations hereunder).
        </span>
      </p>
      <p className="c5">
        <span className="c6 c1">Assignment</span>
        <span className="c2 c1">
          . You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your
          Services account, in any way (by operation of law or otherwise) without Realm&#39;s prior written consent. We
          may transfer, assign, or delegate these Terms and our rights and obligations without consent.
        </span>
      </p>
      <p className="c5" id="h.30j0zll">
        <span className="c6 c1">Choice of Law</span>
        <span className="c2 c1">
          . These Terms are governed by and will be construed under the Federal Arbitration Act, applicable federal law,
          and the laws of the State of California, without regard to the conflicts of laws provisions thereof.
        </span>
      </p>
      <p className="c5">
        <span className="c6 c1 c28">Arbitration Agreement</span>
        <span className="c1 c7">. </span>
        <span className="c2 c1">
          Please read the following ARBITRATION AGREEMENT carefully because it requires you to arbitrate certain
          disputes and claims with Realm and limits the manner in which you can seek relief from Realm. Both you and
          Realm acknowledge and agree that for the purposes of any dispute arising out of or relating to the subject
          matter of these Terms, Realm&#39;s officers, directors, employees and independent contractors
          (&ldquo;Personnel&rdquo;) are third-party beneficiaries of these Terms, and that upon your acceptance of these
          Terms, Personnel will have the right (and will be deemed to have accepted the right) to enforce these Terms
          against you as the third-party beneficiary hereof.
        </span>
      </p>
      <p className="c5">
        <span className="c1 c7">(a) Arbitration Rules; Applicability of Arbitration Agreement</span>
        <span className="c1">
          . The parties shall use their best efforts to settle any dispute, claim, question, or disagreement arising out
          of or relating to the subject matter of these Terms directly through good-faith negotiations, which shall be a
          precondition to either party initiating arbitration. If such negotiations do not resolve the dispute, it shall
          be finally settled by binding arbitration in San Francisco County, California. The arbitration will proceed in
          the English language, in accordance with the JAMS Streamlined{' '}
        </span>
        <span className="c1 c17">
          Arbitration Rules and Procedures (the &ldquo;Rules&rdquo;) then in effect, by one commercial arbitrator with
          substantial experience in resolving intellectual property and commercial contract disputes. The arbitrator
          shall be selected from the appropriate list of JAMS arbitrators in accordance with such Rules. Judgment upon
          the award rendered by such arbitrator may be entered in any court of competent jurisdiction.
        </span>
      </p>
      <p className="c5">
        <span className="c1 c17">(b) </span>
        <span className="c1 c7 c17">Costs of Arbitration</span>
        <span className="c2 c1 c17">
          . The Rules will govern payment of all arbitration fees. Realm will pay all arbitration fees for claims less
          than seventy-five thousand ($75,000) dollars. Realm will not seek its attorneys&rsquo; fees and costs in
          arbitration unless the arbitrator determines that your claim is frivolous.
        </span>
      </p>
      <p className="c5">
        <span className="c1 c17">(c) </span>
        <span className="c1 c7 c17">Small Claims Court; Infringement</span>
        <span className="c2 c1 c17">
          . Either you or Realm may assert claims, if they qualify, in small claims court in San Francisco County,
          California or any United States county where you live or work. Furthermore, notwithstanding the foregoing
          obligation to arbitrate disputes, each party shall have the right to pursue injunctive or other equitable
          relief at any time, from any court of competent jurisdiction, to prevent the actual or threatened
          infringement, misappropriation or violation of a party&#39;s copyrights, trademarks, trade secrets, patents or
          other intellectual property rights.
        </span>
      </p>
      <p className="c5">
        <span className="c1">(d) </span>
        <span className="c1 c7">Waiver of Jury Trial</span>
        <span className="c1">
          . YOU AND REALM WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A
          JUDGE OR JURY. You and Realm are instead choosing to have claims and disputes resolved by arbitration.
          Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in
          court and are subject to very limited review by a court. In any litigation between you and Realm over whether
          to vacate or enforce an arbitration award, YOU AND REALM WAIVE ALL RIGHTS TO A JURY TRIAL, and elect instead
          to have the dispute be resolved by a judge.
        </span>
      </p>
      <p className="c5">
        <span className="c1">(e) </span>
        <span className="c1 c7">Waiver of Class or Consolidated Actions</span>
        <span className="c1">
          . ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN
          INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR
          LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If however, this waiver of class
          or consolidated actions is deemed invalid or unenforceable, neither you nor Realm is entitled to arbitration;
          instead all claims and disputes will be resolved in a court as set forth in (g) below.
        </span>
        <span className="c2 c8">&nbsp;</span>
      </p>
      <p className="c5">
        <span className="c1">(f) </span>
        <span className="c1 c7">Opt-out</span>
        <span className="c2 c1">
          . You have the right to opt out of the provisions of this Section by sending written notice of your decision
          to opt out to the following address: 333 Hudson Street, suite 201, New York, New York 10013 postmarked within
          thirty (30) days of first accepting these Terms. You must include (i) your name and residence address, (ii)
          the email address and/or telephone number associated with your account, and (iii) a clear statement that you
          want to opt out of these Terms&rsquo; arbitration agreement.
        </span>
      </p>
      <p className="c5">
        <span className="c1">(g) </span>
        <span className="c1 c7">Exclusive Venue</span>
        <span className="c1">
          . If you send the opt-out notice in (f), and/or in any circumstances where the foregoing arbitration agreement
          permits either you or Realm to litigate any dispute arising out of or relating to the subject matter of these
          Terms in court, then the foregoing arbitration agreement will not apply to either party, and both you and
          Realm agree that any judicial proceeding (other than small claims actions) will be brought in the state or
          federal courts{' '}
        </span>
        <span className="c2 c1 c17">
          located in, respectively, San Francisco County, California, or the federal district in which that county
          falls.
        </span>
      </p>
      <p className="c5">
        <span className="c1 c17">(h) </span>
        <span className="c1 c7 c17">Severability</span>
        <span className="c1 c17">
          . If the prohibition against class actions and other claims brought on behalf of third parties contained above
          is found to be unenforceable, then all of the preceding language in this Arbitration Agreement section will be
          null and void. This arbitration agreement will survive the termination of your relationship with Realm.
        </span>
      </p>
      <p className="c5">
        <span className="c6 c1">Miscellaneous</span>
        <span className="c1">
          . You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other
          governmental assessments associated with your activity in connection with the Services, provided that the
          Realm may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit.{' '}
        </span>
        <span className="c2 c1 c17">
          The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any
          further rights hereunder. If any provision of these Terms are found to be unenforceable or invalid, that
          provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise
          remain in full force and effect and enforceable. You and Realm agree that these Terms are the complete and
          exclusive statement of the mutual understanding between you and Realm, and that these Terms supersede and
          cancel all previous written and oral agreements, communications and other understandings relating to the
          subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner,
          or joint venture of Realm, and you do not have any authority of any kind to bind Realm in any respect
          whatsoever.
        </span>
      </p>
      <p className="c25">
        <span className="c2 c1 c17">
          Except as expressly set forth in the section above regarding the arbitration agreement, you and Realm agree
          there are no third-party beneficiaries intended under these Terms.
        </span>
      </p>
    </div>
  </div>
)

interface TermsProps {
  blog_home_data: BlogHomeData
  webview: boolean
}

const Terms: FC<TermsProps> = ({ blog_home_data, webview }) => {
  useEffect(() => {
    trackPage('Terms')
  }, [])

  return (
    <div className={legalStyles.page}>
      {!webview && <Header />}
      <Content />
      {!webview && <Footer blog_home_data={blog_home_data} />}
    </div>
  )
}

export default Terms
