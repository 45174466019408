import { BlogHomeData } from 'pages/_serverRendered/CmsPage/types'
import React, { FC } from 'react'
import cx from 'classnames'

import styles from './styles.module.scss'

interface BlogLinksProps {
  title: string
  blogData?: BlogHomeData
}

const BlogLinks: FC<BlogLinksProps> = ({ title, blogData }) => {
  // Divide blogData into groups of 7 for up to 3 columns.
  // Then keep only 3 columns.
  const items = blogData?.data.body.find((e) => e.slice_type == 'footer_blog_links')?.items || []
  const totalItems = items.length
  const totalGroups = Math.max(1, Math.min(3, Math.ceil(totalItems / 7)))
  const itemsPerGroup = Math.floor(totalItems / totalGroups)

  const desktopNodes: Array<React.ReactNode> = []
  for (let groupId = 0; groupId < totalGroups; groupId++) {
    const groupItems: Array<React.ReactNode> = []

    for (
      let itemIndex = itemsPerGroup * groupId;
      itemIndex < Math.min(totalItems, itemsPerGroup * (groupId + 1));
      itemIndex++
    ) {
      const item = items[itemIndex]
      groupItems.push(
        <a key={`d_${item.blog_post.id}`} href={`/resources/library/${item.blog_post.uid}`}>
          {item.title}
        </a>
      )
    }
    desktopNodes.push(
      <div className={cx(styles.linkGroup, styles.desktop)} key={groupId}>
        <h5>{!groupId ? title : ''}</h5>
        {groupItems}
      </div>
    )
  }
  return (
    <>
      {desktopNodes}
      <div className={cx(styles.linkGroup, styles.mobile)}>
        <h5>{title}</h5>
        {items.map((item) => (
          <a key={`m_${item.blog_post.id}`} href={`/resources/library/${item.blog_post.uid}`}>
            {item.title}
          </a>
        ))}
      </div>
    </>
  )
}

export default BlogLinks
