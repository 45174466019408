import React, { ChangeEvent, FC, FormEvent, useCallback, useMemo, useState } from 'react'

import Header from 'components/MarketingSite/Header'
import { BlogHomeData } from 'pages/_serverRendered/CmsPage/types'

import styles from './styles.module.scss'
import CoreInput from 'components/Core/CoreInput'
import CoreButton from 'components/Core/CoreButton'
import Checkbox from 'components/Checkbox'

import { postJSON } from 'utils/fetch'

import RenovationImgPath from 'images/vendor-signup/renovation.jpg'

import PageHeader from './PageHeader'
import ThankYou from 'svgs/vendor-signup/ThankYou'
import PageLoad from 'non-rendering/PageLoad'
import { trackClick } from 'utils/analyticsV2'
import { NavItems } from 'components/MarketingSite/Header/types'
import _ from 'lodash'

const SUPPORTED_STATES = ['CA', 'OR', 'WA']

const vendorNavItems: NavItems = {
  vendorsSignIn: {
    type: 'item',
    title: 'Vendors Sign In',
    href: '/vendors',
  },
  forHomeOwners: {
    type: 'item',
    title: 'For Homeowners',
    href: '/',
  },
}

interface Props {
  blog_home_data: BlogHomeData
  webview: boolean
  cbsa_codes: { [code: string]: string }
}

const VendorSignup: FC<Props> = ({ webview, cbsa_codes }) => {
  const [companyName, setCompanyName] = useState<string>('')
  const [state, setState] = useState<string>('CA')
  const [licenseNumber, setLicenseNumber] = useState<string>('')
  const [heardOfUs, setHeardOfUs] = useState<string>('')
  const [cbsaCodes, setCbsaCodes] = useState<string[]>([])
  const [fullName, setFullName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [website, setWebsite] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [errors, setErrors] = useState<Record<string, string[]>>()

  const companyNameError = useMemo(() => {
    if (!errors) return
    if (!errors['name']) return

    return errors['name'][0]
  }, [errors])

  const contactError = useMemo(() => {
    if (!errors) return
    if (!errors['service_areas.primary_contact']) return

    return errors['service_areas.primary_contact'].join(', ')
  }, [errors])

  const licenseError = useMemo(() => {
    if (!errors) return
    if (!errors['licenses']) return

    return errors['licenses'].join(', ')
  }, [errors])

  const websiteError = useMemo(() => {
    if (!errors) return
    if (!errors['website']) return

    return errors['website'].join(', ')
  }, [errors])

  const handleGoHome = useCallback(() => {
    window.location.href = '/'
  }, [])

  const handleCbsaCodeChange = useCallback(
    (code: string) => {
      return (e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked

        const newCodes =
          checked && !cbsaCodes.includes(code)
            ? [...cbsaCodes, code]
            : !checked && cbsaCodes.includes(code)
              ? cbsaCodes.filter((x) => x != code)
              : cbsaCodes

        setCbsaCodes(newCodes)
      }
    },
    [cbsaCodes]
  )

  const formData = useMemo(() => {
    return {
      vendor: {
        name: companyName,
        website: website,
        licenses_attributes: [{ number: licenseNumber, state }],
        heard_of_us: heardOfUs,
        service_areas_attributes: [
          {
            state,
            primary: true,
            contacts_attributes: [
              {
                full_name: fullName,
                email,
                phone_number: phoneNumber,
                primary: true,
              },
            ],
          },
        ],
        cbsa_codes: cbsaCodes,
      },
    }
  }, [companyName, state, licenseNumber, fullName, email, phoneNumber, website, heardOfUs, cbsaCodes])

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      setLoading(true)

      const response = await postJSON('/vendor-signup', formData)

      if (response.isError) {
        setErrors(response.jsonBody.errors)
        setLoading(false)
      } else {
        setSubmitted(true)
        setLoading(false)
      }
    },
    [formData]
  )

  const handleClickGetStarted = useCallback(() => {
    trackClick({ code: 'get-started', screen: 'vendor-signup', flexfield_1: formData })
  }, [formData])

  return (
    <div className={styles.wrapper}>
      <PageLoad name="vendor-signup" />
      {!webview && <Header business navItems={vendorNavItems} />}
      {submitted ? (
        <div className={styles.thanksContainer}>
          <ThankYou className={styles.check} />
          <PageHeader
            headlineText="Thanks for signing up"
            subheadText="Our team will reach out to you within the next 24 hours with next steps. We can't wait to start working with you!"
          />
          <CoreButton text="Explore the Realm website" onClick={handleGoHome} className={styles.button} />
        </div>
      ) : (
        <div className={styles.formContainer}>
          <div className={styles.contentWrapper}>
            <PageHeader
              className={styles.pageHeader}
              headlineText="Sign up to join the Realm Vendor Network!"
              subheadText="As a Realm vendor, you gain access to our network of renovation-ready homeowners. Open to contractors in our service areas in California, Oregon, and Washington. Start working with us today!"
            />
            <form className={styles.form} onSubmit={handleSubmit}>
              <fieldset disabled={loading}>
                <CoreInput.Text
                  className={styles.row}
                  value={companyName}
                  onChange={setCompanyName}
                  label="Your company name"
                  kind={companyNameError ? 'alert' : 'enabled'}
                  hint={companyNameError}
                />
                <CoreInput.Select className={styles.row} value={state} onChange={setState} label="State" required>
                  {SUPPORTED_STATES.map((state) => {
                    return (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    )
                  })}
                </CoreInput.Select>
                <CoreInput.Text
                  className={styles.row}
                  value={fullName}
                  onChange={setFullName}
                  label="Your name"
                  kind={contactError ? 'alert' : 'enabled'}
                  hint={contactError}
                  required
                />
                <CoreInput.Text
                  className={styles.row}
                  value={email}
                  onChange={setEmail}
                  label="Email"
                  type="email"
                  kind={contactError ? 'alert' : 'enabled'}
                  hint={contactError}
                  required
                />
                <CoreInput.Text
                  className={styles.row}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  label="Phone number"
                  type="tel"
                  kind={contactError ? 'alert' : 'enabled'}
                  hint={contactError}
                  required
                />
                <CoreInput.Text
                  className={styles.row}
                  value={website}
                  onChange={setWebsite}
                  label="Website"
                  required
                  kind={websiteError ? 'alert' : 'enabled'}
                  hint={websiteError || 'Optional'}
                />
                <CoreInput.Text
                  className={styles.row}
                  value={licenseNumber}
                  onChange={setLicenseNumber}
                  label="License number"
                  kind={licenseError ? 'alert' : 'enabled'}
                  hint={licenseError || 'Optional'}
                />
                <CoreInput.Select
                  className={styles.row}
                  value={heardOfUs}
                  onChange={setHeardOfUs}
                  label="How did you hear about us?"
                  placeholder="Make a selection..."
                  required
                >
                  <option value="facebook_group">{'Facebook group'}</option>
                  <option value="online_advertisement">{'Online advertisement'}</option>
                  <option value="friend">{'A friend'}</option>
                  <option value="word_of_mouth">{'Word of mouth'}</option>
                  <option value="other">{'Other'}</option>
                </CoreInput.Select>
                <label className="tw-text-sm tw-text-disabled-black">{'Areas served'}</label>
                <div className="tw-flex tw-text-sm tw-gap-4">
                  {_.chunk(Object.keys(cbsa_codes), Object.keys(cbsa_codes).length / 2).map((group, i) => {
                    return (
                      <div key={`group-${i}`} className="tw-grow">
                        {group.map((code) => {
                          return (
                            <div key={code} className="tw-flex tw-my-1.5">
                              <Checkbox
                                type="checkbox"
                                id={`cbsa-code-${code}`}
                                value={code}
                                checked={cbsaCodes.includes(code)}
                                onChange={handleCbsaCodeChange(code)}
                              />
                              <label className="tw-grow" htmlFor={`cbsa-code-${code}`}>
                                {cbsa_codes[code]}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </fieldset>
              <div className={styles.row}>
                <CoreButton
                  className={styles.submit}
                  type="submit"
                  text="Get started"
                  onClick={handleClickGetStarted}
                />
              </div>
            </form>
          </div>
          <img src={RenovationImgPath} alt="A renovation in progress" className={styles.image} />
        </div>
      )}
    </div>
  )
}

export default VendorSignup
