import React, { FC, useEffect } from 'react'

import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'
import Footer from 'components/MarketingSite/Footer'
import Header, { calculateNavItems } from 'components/MarketingSite/Header'
import GetStarted from 'pages/_serverRendered/Homepage/GetStarted'
import PageContent from 'components/MarketingSite/PageContent'
import meetContent from 'pages/_serverRendered/Meet/content'
import { trackPage } from 'utils/analytics'
import { BlogHomeData } from 'pages/_serverRendered/CmsPage/types'

import styles from './styles.module.scss'

interface MeetProps {
  blog_home_data: BlogHomeData
}

const Meet: FC<MeetProps> = ({ blog_home_data }) => {
  useEffect(() => {
    trackPage('Meet')
  }, [])

  return (
    <div>
      <Header activeNavItem={calculateNavItems().Meet}>
        <AddressAutocomplete positionLabel="header" />
      </Header>
      <PageContent content={meetContent} />
      <GetStarted className={styles.getStarted} />
      <Footer blog_home_data={blog_home_data} />
    </div>
  )
}

export default Meet
