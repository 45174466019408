import CheckCircle from '@mui/icons-material/CheckCircle'
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked'
import CoreInput from 'components/Core/CoreInput'
import CoreList from 'components/Core/CoreList'
import Container from 'pages/_serverRendered/AeEventSignup/components/Container'
import {
  EventsMode,
  EventsPackage,
  eventsPropsState,
  eventsSelectedAeIdState,
  eventsSelectedMarketState,
  eventsSelectedModeState,
  eventsSelectedPackagePriceInDollarsState,
  eventsSelectedPackageState,
} from 'recoil/events'
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import cx from 'classnames'
import CoreText from 'components/Core/CoreText'
import CoreButton from 'components/Core/CoreButton'
import Info from '@mui/icons-material/Info'

const PickTask: FC = () => {
  const [selectedAeId, setSelectedAeId] = useRecoilState(eventsSelectedAeIdState)
  const [selectedMode, setSelectedMode] = useRecoilState(eventsSelectedModeState)
  const [selectedPackage, setSelectedPackage] = useRecoilState(eventsSelectedPackageState)
  const [selectedPrice, setSelectedPrice] = useRecoilState(eventsSelectedPackagePriceInDollarsState)
  const [selectedMarket, setSelectedMarket] = useRecoilState(eventsSelectedMarketState)

  const location = useLocation()
  const { success } = (location.state as { success?: boolean }) || {}

  const [customizingPrice, setCustomizingPrice] = useState(false)
  const props = useRecoilValue(eventsPropsState)

  const history = useHistory()

  const handleNext = useCallback(() => {
    history.push('user-info')
  }, [history])

  const handleClickMode = useCallback(
    (value: EventsMode) => {
      return () => setSelectedMode(value)
    },
    [setSelectedMode]
  )

  const handleClickPackage = useCallback(
    (value: EventsPackage) => {
      return () => setSelectedPackage(value)
    },
    [setSelectedPackage]
  )

  const handleClickCustomizePrice = useCallback(() => {
    setCustomizingPrice(true)
  }, [])

  const handleChangePrice = useCallback(
    (value: string) => {
      setSelectedPrice(value ? parseInt(value) : null)
    },
    [setSelectedPrice]
  )

  const valid = useMemo(() => {
    if (!selectedMode) return false
    if (!selectedAeId) return false
    if (!selectedMarket) return false

    if (selectedMode != 'deposit') return true

    return !!selectedPackage
  }, [selectedMode, selectedPackage, selectedAeId, selectedMarket])

  useEffect(() => {
    if (selectedPackage) {
      const newSelectedPrice = props?.packages?.find((x) => x == selectedPackage)?.price_in_dollars
      if (newSelectedPrice) setSelectedPrice(newSelectedPrice)
    }
  }, [setSelectedPrice, props?.packages, selectedPackage])

  useEffect(() => {
    if (selectedMarket && props?.sales_events) {
      if (props.sales_events.findIndex((x) => x.name == selectedMarket) < 0) setSelectedMarket('')
    }
    if (selectedAeId && props?.aes) {
      if (props.aes.findIndex((x) => x.id == selectedAeId) < 0) setSelectedAeId('')
    }
  })

  const modes: { mode: EventsMode; name: string }[] = [
    { mode: 'meeting', name: 'Schedule a meeting' },
    { mode: 'deposit', name: 'Collect a deposit' },
    { mode: 'just-info', name: 'Just collect info' },
  ]

  return (
    <Container>
      <div className="tw-space-y-1">
        <CoreText.Headline className="tw-text-center tw-text-4xl sm:tw-text-left tw-mb-8 tw-mt-4 sm:tw-mt-0" size="xs">
          {'Realm Signup'}
        </CoreText.Headline>
        {success && (
          <div className={'tw-p-3 tw-flex tw-gap-2 tw-bg-blue-100 tw-text-blue-600'}>
            <Info />
            <div>{'User added!'}</div>
          </div>
        )}
      </div>
      {props?.aes && (
        <div className="tw-space-y-1">
          <CoreInput.Select label="You are" required onChange={setSelectedAeId} value={selectedAeId}>
            {props.aes.map(({ name, id }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
            <option disabled value="">
              {'Select an option'}
            </option>
          </CoreInput.Select>
          <div className="tw-text-xs tw-text-neutrals-600 tw-px-2">
            {'Not listed here? You may be looking for the '}
            <a className="tw-underline tw-text-green-600" href="/event-signup">
              {'simple event signup form'}
            </a>
            {'.'}
          </div>
        </div>
      )}
      {props?.sales_events && (
        <CoreInput.Select label="Name of market" required value={selectedMarket} onChange={setSelectedMarket}>
          {props.sales_events.map((event) => (
            <option key={event.name} value={event.name}>
              {event.name}
            </option>
          ))}
          <option disabled value="">
            {'Select an option'}
          </option>
        </CoreInput.Select>
      )}
      <div className="tw-space-y-1">
        <CoreText.Paragraph size="l" weight="heavy">
          {'What do you want to do?'}
        </CoreText.Paragraph>
        <CoreList>
          {modes.map(({ mode, name }) => {
            return (
              <Fragment key={mode}>
                <CoreList.Item
                  className="!tw-bg-transparent tw-px-0"
                  text={name}
                  leftIcon={
                    selectedMode == mode ? (
                      <CheckCircle className={cx('tw-w-6 tw-h-6 !tw-text-green-600')} />
                    ) : (
                      <RadioButtonUnchecked />
                    )
                  }
                  onClick={handleClickMode(mode)}
                />
                {props?.packages && mode == 'deposit' && selectedMode == 'deposit' && (
                  <CoreList>
                    {props.packages.map((pkg) => {
                      return (
                        <CoreList.Item
                          key={pkg.id}
                          text={pkg.name}
                          leftIcon={
                            selectedPackage == pkg ? (
                              <CheckCircle className={cx('tw-w-6 tw-h-6 !tw-text-green-600')} />
                            ) : (
                              <RadioButtonUnchecked />
                            )
                          }
                          right={
                            pkg == selectedPackage && customizingPrice ? (
                              <div className="tw-flex tw-items-center">
                                <div className="tw-text-xs tw-line-through tw-mr-2 tw-text-neutrals-500">
                                  {pkg.price_in_dollars}
                                </div>
                                <div>{'$'}</div>
                                <CoreInput.Text
                                  type="number"
                                  value={selectedPrice?.toString() || ''}
                                  onChange={handleChangePrice}
                                  label="Price"
                                  className="tw-w-24"
                                />
                              </div>
                            ) : (
                              <div className="tw-space-y-1 tw-text-right">
                                <div className="tw-text-neutrals-500">{`$${pkg.price_in_dollars}`}</div>
                                {pkg == selectedPackage && (
                                  <button
                                    type="button"
                                    className="tw-underline tw-text-green-600 tw-text-xs"
                                    onClick={handleClickCustomizePrice}
                                  >
                                    {'Customize Price'}
                                  </button>
                                )}
                              </div>
                            )
                          }
                          onClick={handleClickPackage(pkg)}
                        />
                      )
                    })}
                  </CoreList>
                )}
              </Fragment>
            )
          })}
        </CoreList>
      </div>

      <CoreButton
        disabled={!valid}
        kind={valid ? 'primary' : 'disabled'}
        onClick={handleNext}
        text="Next"
        className="tw-w-full"
      />
    </Container>
  )
}

export default PickTask
