import React, { FC, useCallback } from 'react'
import classnames from 'classnames'

import { track } from 'utils/analytics'

import { GetStartedData } from '../types'
import { useCTAAdjustment } from '../CtaAdjustment'

import ArrowIcon from 'svgs/arrow'

import AddressAutocompleteField from '../AddressAutocompleteField'

import Background from './Background'
import TitleHeader from '../Sections/Components/TitleHeader'
import Button from 'components/NikhilButton'

import styles from './styles.module.scss'

interface Props extends GetStartedData {
  className?: string
  variant?: string
  onRequestHeaderHeight: () => number | undefined
}

const GetStarted: FC<Props> = ({
  className,
  pre_title,
  title,
  description,
  address_label,
  variant,
  onRequestHeaderHeight,

  address_autocomplete_visible,
  show_cta,
  cta_text,
  cta_referral_utm_params,
  cta_forward_utm_params,
  cta_url,
  cta_relative_url,
}) => {
  const { url } = useCTAAdjustment(cta_url?.url, cta_referral_utm_params, cta_forward_utm_params)
  const handleCtaClick = useCallback(async () => {
    await track('click cta', { cta: 'landing-page-get-started', module: 'get-started' })

    if (!cta_url && !cta_relative_url) return
    if (!url && !cta_relative_url) return

    if (url && cta_url && cta_url.target === '_blank') {
      window.open(url, '_blank')?.focus()
    } else {
      window.location.href = url || cta_relative_url || ''
    }
  }, [cta_url, url, cta_relative_url])

  return (
    <section className={classnames(styles.getStarted, className)} data-hide-on="logged-in">
      <div className={styles.content}>
        <Background />
        <TitleHeader className={styles.titleBox} pre_title={pre_title} title={title} post_title={description} />
        {address_autocomplete_visible != false && (
          <AddressAutocompleteField
            containerClassName={styles.addressField}
            className={styles.input}
            showButton={false}
            variant={variant}
            positionLabel="getStarted"
            placeholder={address_label}
            onRequestHeaderHeight={onRequestHeaderHeight}
            scrollToFocus
          />
        )}
        {show_cta && (
          <Button className={styles.cta} type="button" onClick={handleCtaClick}>
            {cta_text || 'Get Started'}
            <ArrowIcon />
          </Button>
        )}
      </div>
    </section>
  )
}

export default GetStarted
