import { RichTextBlock, Link as PrismicLinkData } from 'prismic-reactjs'

export type BrandColor =
  | 'white'
  | 'transparent'
  | 'moss-fine'
  | 'moss-light'
  | 'moss-regular'
  | 'moss-bright'
  | 'sonne-fine'
  | 'sonne-light'
  | 'sonne-regular'
  | 'sonne-bright'
  | 'lapis-fine'
  | 'lapis-light'
  | 'lapis-regular'
  | 'lapis-bright'
  | 'tangerine-fine'
  | 'tangerine-light'
  | 'tangerine-regular'
  | 'tangerine-bright'

export interface HeroData {
  title: RichTextBlock[]
  subtitle: RichTextBlock[]
  description: RichTextBlock[]
  include_cta: boolean
  media: PrismicLinkData
  is_media_infographic: boolean
  address_autocomplete_visible: boolean
  show_autocomplete_button: boolean
  override_button_label_long: string
  override_button_label_short: string
  type: 'default' | 'bullets' | 'homepage'
  address_label?: string

  show_cta?: boolean
  cta_text?: string
  cta_url?: PrismicLinkData
  cta_relative_url?: string
}

export interface GetStartedData {
  show_get_started?: boolean
  pre_title: RichTextBlock[]
  title: RichTextBlock[]
  description: RichTextBlock[]
  address_label: string

  address_autocomplete_visible?: boolean
  show_cta?: boolean
  cta_text?: string
  cta_url?: PrismicLinkData
  cta_relative_url?: string
}

export interface IntroData {
  title: RichTextBlock[]
  body: RichTextBlock[]
}

interface SharedSlicePrimary {
  background_color?: BrandColor
  description?: RichTextBlock[]
  title?: RichTextBlock[]
  cta_title?: string
  cta_url?: string
  nav_title?: string
}

export interface SliceAccordion {
  id?: string
  slice_type?: 'accordion'
  primary?: {
    subtitle: RichTextBlock[]
    preopen: boolean
  } & SharedSlicePrimary
  items: {
    body1: RichTextBlock[]
    cta_title?: string
    cta_url?: string
    header: RichTextBlock[]
    title: RichTextBlock[]
    image?: RichTextBlock
  }[]
}

export interface SliceCards {
  slice_type: 'cards'
  primary?: SharedSlicePrimary
  items: {
    body1: RichTextBlock[]
    image: RichTextBlock
    title: RichTextBlock[]
  }[]
}

export interface SliceScrollingCards {
  slice_type: 'scrolling_cards'
  primary?: {
    card_color?: BrandColor
  } & SharedSlicePrimary
  items: {
    body1: RichTextBlock[]
    footer: RichTextBlock[]
    title: RichTextBlock[]
  }[]
}

export interface SliceFlippingCards {
  slice_type: 'flipping_cards'
  primary?: {
    card_color?: BrandColor
  } & SharedSlicePrimary
  items: {
    body1: RichTextBlock[]
    footer: RichTextBlock[]
    image: RichTextBlock
    title: RichTextBlock[]
  }[]
}

export interface SliceFormattedText {
  slice_type: 'formatted_text'
  primary: {
    text?: RichTextBlock[]
  } & SharedSlicePrimary
}

export interface SliceLandingCards {
  slice_type: 'landing_cards'
  primary?: SharedSlicePrimary
  items: {
    body1: RichTextBlock[]
    image: RichTextBlock
    title: RichTextBlock[]
  }[]
}

export interface SliceCustomEmbed {
  slice_type: 'custom_embed'
  primary: {
    html_code: RichTextBlock[]
  } & SharedSlicePrimary
}

export interface SliceCustomerLogos {
  slice_type: 'customer_logos'
  primary: {
    media: PrismicLinkData
  } & SharedSlicePrimary
  items: {
    logo: RichTextBlock
    link: PrismicLinkData
  }[]
}

export interface SliceListCard {
  slice_type: 'list_card'
  primary: {
    title1: RichTextBlock[] // XXX: this has title1, SharedSlicePrimary has title
    image: RichTextBlock
    subheader: RichTextBlock[]
    bullets: RichTextBlock[]
    bullets_style: 'unstyled' | 'bullets' | 'checkmarks' | 'numbers'
    closing_text: RichTextBlock[]
    cta_text: string // XXX: this has cta_text, SharedSlicePrimary has cta_title
    cta_url: PrismicLinkData // XXX: this is PrismicLinkData, SharedSlicePrimary is string
  } & SharedSlicePrimary
}

export interface SlicePhotoCard {
  slice_type: 'photo_card'
  primary?: SharedSlicePrimary
  items: {
    image: RichTextBlock
    title: RichTextBlock[]
    description: RichTextBlock[]
  }[]
}

export interface SliceQuotesCard {
  slice_type: 'quotes_card'
  primary?: SharedSlicePrimary
  items: {
    body1: RichTextBlock[]
    footer: RichTextBlock[]
    title: RichTextBlock[]
  }[]
}

export interface SliceImageQuotes {
  slice_type: 'image_quotes'
  primary?: {
    title1: RichTextBlock[] // XXX: this has title1, SharedSlicePrimary has title
  } & SharedSlicePrimary
  items: {
    image: RichTextBlock
    name: string
    location: string
    quote: RichTextBlock[]
    link: PrismicLinkData
  }[]
}

export interface SliceBlogHighlights {
  slice_type: 'blog_highlights'
  primary?: SharedSlicePrimary
  items: {
    blog_post_image: RichTextBlock
    author_picture: RichTextBlock
    author_name: string
    author_role: string
    blog_post_title: RichTextBlock[]
    blog_post_snippet: RichTextBlock[]
    blog_post_url: PrismicLinkData
  }[]
}

export interface SliceTeam {
  slice_type: 'team'
  primary: {
    title1: RichTextBlock[] // XXX: this has title1, SharedSlicePrimary has title
    cta_url?: PrismicLinkData // XXX: this is PrismicLinkData, SharedSlicePrimary is string
  } & SharedSlicePrimary
  items: {
    picture: RichTextBlock
    name: string
    role: string
  }[]
}

export interface SliceOpenRoles {
  slice_type: 'open_roles'
  primary: {
    title1: RichTextBlock[] // XXX: this has title1, SharedSlicePrimary has title
    cta_url?: PrismicLinkData // XXX: this is PrismicLinkData, SharedSlicePrimary is string
  } & SharedSlicePrimary
  items: {
    role_name: string
    role_url: PrismicLinkData
  }[]
}

export interface SliceBackers {
  slice_type: 'backers'
  primary: {
    title1: RichTextBlock[] // XXX: this has title1, SharedSlicePrimary has title
  } & SharedSlicePrimary
  items: {
    logo: RichTextBlock
    link: PrismicLinkData
  }[]
}

export interface SlicePress {
  slice_type?: 'press'
  primary?: {
    title1: RichTextBlock[] // XXX: this has title1, SharedSlicePrimary has title
    cta_url?: PrismicLinkData // XXX: this is PrismicLinkData, SharedSlicePrimary is string
  } & SharedSlicePrimary
  items: {
    quote: RichTextBlock[]
    source: string
    link: PrismicLinkData
  }[]
}

export type Slice =
  | SliceAccordion
  | SliceBackers
  | SliceBlogHighlights
  | SliceCards
  | SliceCustomerLogos
  | SliceFlippingCards
  | SliceFormattedText
  | SliceImageQuotes
  | SliceLandingCards
  | SliceCustomEmbed
  | SliceListCard
  | SliceOpenRoles
  | SlicePhotoCard
  | SlicePress
  | SliceQuotesCard
  | SliceScrollingCards
  | SliceTeam

interface FooterBlogSlice {
  slice_type: 'footer_blog_links'
  items: {
    title: string
    blog_post: {
      id: string
      type: 'blog_post'
      tags: string[]
      slug: string
      lang: string
      first_publication_date: string
      last_publication_date: string
      uid: string
      link_type: 'Document'
      isBroken: boolean
    }
  }[]
}

export interface BlogHomeData {
  id: string
  type: 'blog_home'
  href: string
  tags: string[]
  first_publication_date: string
  last_publication_date: string
  slugs: string[]
  lang: string
  alternate_languages: string[]
  data: {
    highlighted_blog_post: {
      id: string
      type: 'blog_post'
      tags: string[]
      slug: string
      lang: string
      first_publication_date: string
      last_publication_date: string
      uid: string
      link_type: 'Document'
      isBroken: boolean
    }
    body: FooterBlogSlice[]
  }
}

export enum AutocompleteRedirectTargetTypes {
  Default = 'Default',
  ProjectPricing = '/l/project-pricing',
  RenovationPricing = '/l/renovation-pricing',
  HomeReport = '/l/home-report',
}

export interface CmsPageDataBase {
  intro: IntroData[]
  get_started: GetStartedData[]
  hero: HeroData[]
  nav_how_it_works: boolean
  nav_resources: boolean
  nav_about: boolean
  nav_dashboard: boolean
  nav_sign_in: boolean
  nav_get_started: boolean
  autocomplete_redirect_target: AutocompleteRedirectTargetTypes
  page_id_for_tracking: string

  version?: 'v1' | 'v2'
}

export interface CmsPageData extends CmsPageDataBase {
  body: Slice[]
}
