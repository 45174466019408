import React, { FC } from 'react'

import CmsPage from 'pages/_serverRendered/CmsPage'
import { BlogHomeData, CmsPageData } from 'pages/_serverRendered/CmsPage/types'

const Homepage: FC<{ data: CmsPageData; blog_home_data: BlogHomeData }> = ({ data, blog_home_data }) => {
  return <CmsPage data={data} blog_home_data={blog_home_data} pageName="homepage" />
}

export default Homepage
