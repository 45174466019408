import React, { FC, useState, useMemo, useEffect } from 'react'
import cx from 'classnames'

import { addStickyProps } from 'utils/analytics'

import { Calendar } from './types'

import OverlayMount from 'layouts/components/OverlayMount'
import PageLoad from 'non-rendering/PageLoad'

import PageTitle from 'components/PageTitle'

import RealmBusiness from 'svgs/realm-business'
import Unscheduled from './Unscheduled'
import Scheduled from './Scheduled'
import Canceled from './Canceled'
import Declined from './Declined'

import styles from './styles.module.scss'

interface Props {
  calendar: Calendar
}

const VendorSiteVisit: FC<Props> = ({ calendar: initialCalendar }) => {
  const [calendar, setCalendar] = useState(initialCalendar)

  useEffect(() => {
    if (!calendar.impersonated) return

    addStickyProps({ impersonated: calendar.impersonated }, true)
  }, [calendar.impersonated])

  const status: 'unscheduled' | 'scheduled' | 'canceled' | 'declined' = useMemo(() => {
    if (calendar.event) {
      if (calendar.event.status == 'canceled') return 'canceled'
      if (calendar.event.status == 'declined') return 'declined'

      if (['scheduled', 'occurring', 'completed'].includes(calendar.event.status)) return 'scheduled'
    }

    return 'unscheduled' // Our default.
  }, [calendar])

  useEffect(() => {
    // Scroll to the top  of the page any time our status changes.
    try {
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' } as any)
    } catch {
      // Fallback in case the above isn't supported.
      window.scrollTo(0, 0)
    }
  }, [status])

  useEffect(() => {
    if (!window) return
    // Any time our calendar changes, update CSRF
    document.querySelector('meta[name=csrf-param]')?.setAttribute('content', calendar.csrf_param)
    document.querySelector('meta[name=csrf-token]')?.setAttribute('content', calendar.csrf_token)
  }, [calendar])

  return (
    <OverlayMount>
      <PageLoad name="vendor-site-visit-scheduler" />
      <PageTitle title="Schedule Site Visit" />
      {calendar.impersonated && (
        <div className={styles.impersonation}>
          {'You are now impersonating '}
          {calendar.prefill_email ? <strong>{calendar.prefill_email}</strong> : 'an unspecified vendor'}
        </div>
      )}
      <div className={styles.vendorSiteVisit}>
        <div className={cx(styles.background, { [styles.scheduled]: status == 'scheduled' })} />
        <div className={styles.page}>
          <RealmBusiness className={styles.logo} />
          <div className={styles.content}>
            {status == 'declined' ? (
              <Declined calendar={calendar} />
            ) : status == 'scheduled' ? (
              <Scheduled calendar={calendar} onCalendarChange={setCalendar} />
            ) : status == 'canceled' ? (
              <Canceled calendar={calendar} onCalendarChange={setCalendar} />
            ) : (
              <Unscheduled calendar={calendar} onCalendarChange={setCalendar} />
            )}
          </div>
        </div>
      </div>
    </OverlayMount>
  )
}

export default VendorSiteVisit
