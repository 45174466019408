import React, { FC } from 'react'

import { BlogHomeData } from '../types'

import Instagram from 'svgs/social/instagram'
import Twitter from 'svgs/social/twitter'
import Facebook from 'svgs/social/facebook'
import LinkedIn from 'svgs/social/linkedin'

import BlogLinks from './BlogLinks'
import { RealmLogoSmall } from 'components/MarketingSite/Header/RealmLogo'

import styles from './styles.module.scss'

interface FooterProps {
  blog_home_data?: BlogHomeData
}

const Footer: FC<FooterProps> = ({ blog_home_data }) => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.brand}>
        <RealmLogoSmall className={styles.logo} />
      </div>
      <div className={styles.links}>
        <div className={styles.linkGroup}>
          <h5>{'Learn More'}</h5>
          <a href="/">{'Home'}</a>
          <a href="/how-it-works/what-is-realm">{'What is Realm?'}</a>
          <a href="/how-it-works/renovate-with-realm">{'Renovate with Realm'}</a>
          <a href="/crawl/properties">{'Properties'}</a>
          <a href="/resources/library" target="_blank" rel="noreferrer">
            {'Resources'}
          </a>
          <a href="/about-us">{'About'}</a>
          <a href="/about-us/careers">{'Careers'}</a>
          <a href="/compare">{'Compare'}</a>
        </div>
        <BlogLinks title={'Resources'} blogData={blog_home_data} />
      </div>
    </div>
    <div className={styles.final}>
      <ul className={styles.social}>
        <li>
          <a href="https://www.instagram.com/realm/" target="_blank" rel="noreferrer">
            <Instagram />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/realmhome" target="_blank" rel="noreferrer">
            <Twitter />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/realmhome" target="_blank" rel="noreferrer">
            <Facebook />
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/realmhome/" target="_blank" rel="noreferrer">
            <LinkedIn />
          </a>
        </li>
      </ul>
      <div className={styles.legal}>
        <div className={styles.copyright}>{`© ${new Date().getFullYear()} Realm · All rights reserved`}</div>
        <div className={styles.links}>
          <a href="/privacy">{'Privacy Policy'}</a>
          {' · '}
          <a href="/terms">{'Terms of Use'}</a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
