import React, { FC, useCallback } from 'react'

import SecurityIcon from 'svgs/admin-panel-settings'
import Spinner from 'svgs/spinner'

import CoreModal from 'components/Core/CoreModal'
import CoreText from 'components/Core/CoreText'
import CoreInput from 'components/Core/CoreInput'
import CoreButton from 'components/Core/CoreButton'

import styles from './ConfirmIdentityModal.module.scss'

interface Props {
  email: string
  emailError?: string
  submitting: boolean
  onClose?: () => void
  onEmailChange: (email: string) => void
}

const ConfirmIdentityModal: FC<Props> = ({ email, emailError, submitting, onClose, onEmailChange }) => {
  const handleOnSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      if (onClose) onClose()
    },
    [onClose]
  )
  const renderButtons = useCallback(
    () => (
      <CoreButton
        text="Submit"
        type="submit"
        form="frmConfirmIdentity"
        disabled={submitting}
        icon={submitting ? <Spinner strokeWidth={16} /> : undefined}
      />
    ),
    [submitting]
  )
  return (
    <CoreModal
      title={"Confirm your identity to see homeowner's availability"}
      buttons={renderButtons}
      icon={<SecurityIcon />}
      className={styles.confirmIdentityModal}
    >
      <CoreText.Paragraph weight="light">
        {
          'We are dedicated to ensuring the privacy of everbody collaborating with Realm. Use your Vendor Email to verify your identity and arrange a visit to the site.'
        }
      </CoreText.Paragraph>
      <form id="frmConfirmIdentity" onSubmit={handleOnSubmit}>
        <CoreInput.Text
          className={styles.email}
          name="event[email]"
          type="email"
          autoComplete="email"
          autoFocus={true}
          placeholder="Email"
          required
          readOnly={submitting}
          value={email}
          onChange={onEmailChange}
          hint={emailError}
          kind={emailError ? 'alert' : 'enabled'}
        />
      </form>
    </CoreModal>
  )
}

export default ConfirmIdentityModal
