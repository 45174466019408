import React, { FC } from 'react'
import cx from 'classnames'

import { SliceFoldingItems } from '../../types'

import PresentRichText from 'pages/_serverRendered/CmsPage/Section/Components/PresentRichText'

import TitleHeader from '../Components/TitleHeader'

import styles from './styles.module.scss'

interface Props {
  className?: string
  slice: SliceFoldingItems
}
const FoldingItems: FC<Props> = ({ className, slice }) => {
  return (
    <section className={cx(styles.foldingItems, className)}>
      <TitleHeader className={styles.titleBox} {...slice.primary} />
      <ul className={styles.items}>
        {slice.items.map((item) => (
          <li key={item.reference_id}>
            <PresentRichText className={styles.title} render={item.item_title} id={item.reference_id} />
            <PresentRichText className={styles.info} render={item.item_info} />
          </li>
        ))}
      </ul>
    </section>
  )
}

export default FoldingItems
